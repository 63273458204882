const colors = {
  transparent: 'transparent',
  black: '#0a0b0a',
  white: '#f1f1f1',
  primary: '#81d600',
  secondary: '#88f2ce',
  social: {
    facebook: '#1877f2',
    linkedin: '#0077b5',
    instagram: '#c13584',
    youtube: '#ff0000',
  },
  coolColor: {
    1: '#a1f2f2',
    2: '#f6aea1',
    3: '#3ae374',
    4: '#0198e1',
    5: '#e5b8ff',
    6: '#ffbf00',
    7: '#fd6267',
    8: '#f2cea1',
  },
  darkCoolColor: {
    1: '#3f7f7f',
    2: '#a05252',
    3: '#1d7a4d',
    4: '#0176a8',
    5: '#a06eaa',
    6: '#b59300',
    7: '#c54c50',
    8: '#c19a7e',
  },
  colorHot: {
    1: '#f2cea1',
    2: '#3ae374',
    3: '#0198e1',
    4: '#e5b8ff',
    5: '#ffbf00',
    6: '#fd6267',
  },
  darkColorHot: {
    1: '#c1917e',
    2: '#1d7a4d',
    3: '#0176a8',
    4: '#a06eaa',
    5: '#b59300',
    6: '#c54c50',
  },
  green: {
    100: '#eeffd5',
    200: '#ddffaa',
    300: '#cdff80',
    400: '#bcff56',
    500: '#abff2c',
    600: '#9aff01',
    700: '#6eb700',
    800: '#5b9700',
    900: '#487700',
    1000: '#355800',
  },
  mint: {
    100: '#e1fcf3',
    200: '#c4f9e6',
    300: '#a6f5da',
    400: '#65eebf',
    500: '#41eab1',
    600: '#1ee6a2',
    700: '#16c88b',
    800: '#12a473',
    900: '#0e8158',
    1000: '#0a5e41',
  },
  neutral: {
    100: '#e3e3e3',
    200: '#cccbcb',
    300: '#b5b3b3',
    400: '#9f9c9c',
    500: '#898483',
    600: '#726c6c',
    700: '#5a5555',
    800: '#433f3e',
    900: '#2b2928',
    1000: '#151413',
  },
  success: {
    100: '#ccffbe',
    200: '#86ff7d',
    300: '#49ff3c',
    400: '#0dc200',
    500: '#098a00',
  },
  warning: {
    100: '#fef9b8',
    200: '#fef271',
    300: '#fdec2a',
    400: '#ad9f02',
    500: '#7c7201',
  },
  error: {
    100: '#f9cccc',
    200: '#f39999',
    300: '#ec6666',
    400: '#c11818',
    500: '#891111',
  },
};

export default colors;
