import React from 'react';

export const IconHourcoding = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" {...props}>
      <path
        fill="currentColor"
        d="M26 13h76c0 13.656-.583 24.046-10 34-3.795 3.652-7.614 7.076-12 10l1 3c.068 1.478.085 2.958.063 4.438l-.028 2.308L81 69v4c1.95 1.406 3.96 2.73 6 4 8.07 7.263 12.317 14.475 15 25v14H26c0-14.766 1.283-24.99 11-36 2.532-2.243 5.158-4.171 8-6l3-2-1-3a72.563 72.563 0 01-.063-4.438l.028-2.308L47 60v-4c-1.978-1.677-1.978-1.677-4.563-3.125C32.89 46.515 28.733 37.93 26 27V13zm13 12c1.21 7.265 3.525 12.159 9.293 16.875C55.479 46.752 61.323 47.92 70 47c7.682-2.39 13.208-6.84 17.027-13.898C88.324 30.269 89 28.134 89 25H39zm21.063 36.125c-1.327 1.91-1.327 1.91-.75 4.563L60 68c4.095 1.142 4.095 1.142 7.938-.125 1.326-1.91 1.326-1.91.75-4.563L68 61c-4.095-1.142-4.095-1.142-7.938.125zm-15.5 29.25C41.404 94.472 39 98.722 39 104h50c-1.21-7.265-3.525-12.159-9.293-16.875-11.574-7.854-25.332-7.43-35.144 3.25z"
      ></path>
    </svg>
  );
};
