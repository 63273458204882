import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromChildren,
} from 'react-router-dom';
import {
  LazyBase64Decoder,
  LazyBase64Encoder,
  LazyCodeViewer,
  LazyDocViewer,
  LazyImageEditor,
  LazyImageOptimizer,
  LazyJsonFormatter,
  LazyLandingPage,
  LazyPdfViewer,
  LazyPublicHost,
  LazyQrGenerator,
  LazyWhiteboard,
} from './lazyScreens/publicScreens';

const ToolsRoutes: React.ReactNode = (
  <>
    <Route path="qr-generator" element={<LazyQrGenerator />} />
    <Route path="image-optimizer" element={<LazyImageOptimizer />} />
    <Route path="image-editor" element={<LazyImageEditor />} />
    <Route path="whiteboard" element={<LazyWhiteboard />} />
    <Route path="doc-viewer" element={<LazyDocViewer />} />
    <Route path="pdf-viewer" element={<LazyPdfViewer />} />
    <Route path="xml-editor" element={<LazyImageEditor />} />
    <Route path="json-editor" element={<LazyImageEditor />} />
    <Route path="code-viewer" element={<LazyCodeViewer />}>
      <Route path="json" element={<LazyImageEditor />} />
      <Route path="xml" element={<LazyImageEditor />} />
      <Route path="html" element={<LazyImageEditor />} />
      <Route path="css" element={<LazyImageEditor />} />
      <Route path="js" element={<LazyImageEditor />} />
    </Route>
    <Route path="json-formatter" element={<LazyJsonFormatter />} />
    <Route path="who-is" element={<LazyImageEditor />} />
    <Route path="ip-lookup" element={<LazyImageEditor />} />
    <Route path="dns-lookup" element={<LazyImageEditor />} />
    <Route path="base64-encoder" element={<LazyBase64Encoder />} />
    <Route path="base64-decoder" element={<LazyBase64Decoder />} />
    <Route path="epoch-converter" element={<LazyImageEditor />} />
  </>
);

export const getAppRouter = (isUserLogin: boolean) => {
  return createBrowserRouter(
    createRoutesFromChildren(
      <Route path="/" element={<LazyPublicHost />}>
        <Route path="" element={<LazyLandingPage />} />
        {ToolsRoutes}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>,
    ),
  );
};
