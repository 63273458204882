import { LazyProvider } from '@providers';
import { lazy } from 'react';

// [Start]-------- Host screen --------

const Host = lazy(() => import('@screens/Host/Host'));

export const LazyPublicHost = () => (
  <LazyProvider>
    <Host />
  </LazyProvider>
);

// [End]-------- Host screen --------

// [Start]-------- Landing screens --------

const LandingPage = lazy(() => import('@screens/landingPage/LandingPage'));

export const LazyLandingPage = () => (
  <LazyProvider>
    <LandingPage />
  </LazyProvider>
);

// [End]-------- Landing screens --------

// [Start]-------- QrGenerator screen --------

const QrGenerator = lazy(() => import('@screens/QrGenerator/QrGenerator'));

export const LazyQrGenerator = () => (
  <LazyProvider>
    <QrGenerator />
  </LazyProvider>
);

// [End]-------- QrGenerator screen --------

// [Start]-------- Image editor screen --------

const ImageEditor = lazy(() => import('@screens/ImageEditor/ImageEditor'));

export const LazyImageEditor = () => (
  <LazyProvider>
    <ImageEditor />
  </LazyProvider>
);

const ImageOptimizer = lazy(
  () => import('@screens/ImageOptimizer/ImageOptimizer'),
);

export const LazyImageOptimizer = () => (
  <LazyProvider>
    <ImageOptimizer />
  </LazyProvider>
);

// [End]-------- Image editor screen --------

// [Start]-------- Whiteboard screen --------

const Whiteboard = lazy(() => import('@screens/Whiteboard/Whiteboard'));

export const LazyWhiteboard = () => (
  <LazyProvider>
    <Whiteboard />
  </LazyProvider>
);

// [End]-------- Whiteboard screen --------

// [Start]-------- viewer screen --------

const DocViewer = lazy(() => import('@screens/DocViewer/DocViewer'));

export const LazyDocViewer = () => (
  <LazyProvider>
    <DocViewer />
  </LazyProvider>
);

const PdfViewer = lazy(() => import('@screens/PdfViewer/PdfViewer'));

export const LazyPdfViewer = () => (
  <LazyProvider>
    <PdfViewer />
  </LazyProvider>
);

const CodeViewer = lazy(() => import('@screens/CodeViewer/CodeViewer'));

export const LazyCodeViewer = () => (
  <LazyProvider>
    <CodeViewer />
  </LazyProvider>
);

const JsonFormatter = lazy(
  () => import('@screens/JsonFormatter/JsonFormatter'),
);

export const LazyJsonFormatter = () => (
  <LazyProvider>
    <JsonFormatter />
  </LazyProvider>
);

const Base64Encoder = lazy(
  () => import('@screens/Base64Encoder/Base64Encoder'),
);

export const LazyBase64Encoder = () => (
  <LazyProvider>
    <Base64Encoder />
  </LazyProvider>
);

const Base64Decoder = lazy(
  () => import('@screens/Base64Decoder/Base64Decoder'),
);

export const LazyBase64Decoder = () => (
  <LazyProvider>
    <Base64Decoder />
  </LazyProvider>
);

// [End]-------- viewer screen --------
